import React from "react";

import { Container, Row, Col, Fade  } from "reactstrap";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown"; 
import {showLoading, hideLoading} from "hooks/useLoading";


import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer.js";
import time from "lib/time";
import useAPI from "hooks/useAPI";
import { baseUrl } from "lib/config";

function BlogPost() {
  showLoading();
  React.useEffect(() => {
    document.body.classList.add("blog-post");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  });

  const { id } = useParams();

  const { data } = useAPI(id && `/api/news-stories/${id}`, {
    populate: "*",
  });

  if (!data) return null;

  const { title, description, image, date } = data.data.attributes;
  hideLoading();
  return (
    <Fade> 
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage: `url(${image.data
                ? baseUrl + image.data.attributes.url
                : require("assets/img/brand/sampleblog.png")
                })`,
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white d-none d-lg-block d-md-none d-sm-none hidden-xs">
                  {title}
                </h1>
                <h3 className="title text-white d-none d-lg-none d-md-block d-sm-block d-xs-block">
                  {title}
                </h3>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                {/* <h6 className="category">الأحد, 10 تشرين2/نوفمبر 2019 12:07</h6> */}
                <h6 className="category">{time.weekday(date)}</h6>
                <h3 className="title">{title}</h3>
                <ReactMarkdown>{description}</ReactMarkdown>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </div>
    </Fade>
  );
}

export default BlogPost;
