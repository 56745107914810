import React from "react";
import { Fade } from "reactstrap";
import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer.js";
import Complaint from "components/contact-us/Complaint"; 
function ContactMinister() {
  return (
    <Fade> 
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
        </div>
        <Complaint />
        <Footer />
      </div>
    </Fade>
  );
}

export default ContactMinister;
