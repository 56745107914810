import { baseUrl } from "lib/config";
import React from "react";

import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function BlogCard({ title, description, image, url }) {
  return (
    <section className="blogs-3">
      <Container>
        <Row>
          <Col className="mx-auto" lg="10" md="8">
            <Card className="card-blog card-plain blog-horizontal mb-5">
              <Row>
                <Col lg="4">
                  <div className="card-image shadow">
                    <Link to={url}>
                      <img
                        alt={title}
                        className="img rounded"
                        src={
                          image.data
                            ? baseUrl + image.data.attributes.url
                            : require("assets/img/brand/news.png")
                        }
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg="8">
                  <CardBody>
                    <CardTitle tag="h3">
                      <Link to={url} className="text-dark">
                        {title}
                      </Link>
                    </CardTitle>
                    <p className="card-description mt-4">
                      {description.slice(0, 200)}
                      <Link to={url} className="text-gray mt-5 lead">
                        ... إقرأ المزيد
                      </Link>
                    </p>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default BlogCard;
