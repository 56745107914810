import React from "react";

import { Row, Col , Fade } from "reactstrap";

import Navbar from "components/navbars/Navbar.js";
import OfficeCard from "components/blogs/OfficeCard.js";
import Footer from "components/footers/Footer";
import Pagination from "components/pagination/paginationComp";
import useAPI from "hooks/useAPI";
import {showLoading, hideLoading} from "hooks/useLoading";

function Offices() {
  showLoading();
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });

  const { data } = useAPI("/api/offices", {
    populate: "*",
    sort: "id:desc",
    pagination: {
      pageSize: 8,
      page: new URLSearchParams(window.location.search).get("page") || 1,
    },
  });

  if (!data) return null;
  hideLoading(); 
  return (
    <Fade>
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">
                  مكاتب وزارة الشؤون الاجتماعية
                </h1>
              </Col>
            </Row>
          </div>
        </div>

        <section className="section">
          {data.data.map(({ id, attributes }) => (
            <OfficeCard key={id} url={`/news/${id}`} {...attributes} />
          ))}
        </section>

        <Pagination pagination={data.meta.pagination} />
      </div>
      <Footer />
    </Fade>
  );
}

export default Offices;
