import { Button } from "reactstrap";
import { FaDownload } from "react-icons/fa";

import { baseUrl } from "lib/config";

function File({ caption, url }) {
  return (
    <Button tag="a" href={baseUrl + url} download>
      <span className="ml-2">{caption}</span>
      <FaDownload />
    </Button>
  );
}

export default File;
