import { baseUrl, __DEV__ } from "../lib/config";

function api(endpoint) {
  return baseUrl + endpoint;
}

function authHeaders(token = "") {
  const headers = new Headers();

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  return headers;
}

async function send({ method, endpoint, data, token }) {
  const headers = authHeaders(token);

  headers.append("Content-Type", "application/json");

  const init = {
    headers,
    method,
    body: JSON.stringify(data),
  };

  return fetch(api(endpoint), init);
}

const statusNetworkError = 499;

async function request(options) {
  const { method } = options;

  try {
    const res = await send(options);

    if (__DEV__) {
      if (options.data) {
        console.log("-------- data ---------");
        console.log(options.data);
        console.log("-------- data ---------");
      }
      console.log(method, res.url);
    }

    const contentType = res.headers.get("Content-Type");

    let data = { data: null };

    switch (contentType) {
      case "application/pdf":
        data = await res.blob();
        break;

      default:
        data = await res.json();
        break;
    }

    const { error } = data;

    if (error) {
      if (!error.details) {
        error.details = {
          msg: error.message,
        };
      }

      if (__DEV__) {
        console.error(data.error);
      }
    }

    return data;
  } catch (error) {
    const { message } = error;

    return {
      data: null,
      error: {
        status: statusNetworkError,
        name: message,
        message,
        details: {
          msg: "خطأ في الإتصال",
        },
      },
    };
  }
}

export async function fetcher(endpoint, token) {
  const res = await fetch(api(endpoint), { headers: authHeaders(token) });

  if (__DEV__) {
    console.log("GET", res.url);
  }

  const data = await res.json();

  if (data.error) {
    throw new Error(data.error.details.msg);
  }

  return data;
}

const http = { request };

export default http;
