import { locale } from "./config";

function createFormat(options) {
  return new Intl.DateTimeFormat(locale, {
    ...options,
    calendar: "gregory",
    timeZone: "Africa/Tripoli",
  });
}

const formats = {
  weekday: createFormat({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    weekday: "long",
  }),
  date: createFormat({ year: "numeric", month: "numeric", day: "numeric" }),
  time: createFormat({ hour: "numeric", minute: "numeric", hour12: true }),
  dateTime: createFormat({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }),
};

function format(fmt, dateString) {
  try {
    return fmt.format(new Date(dateString));
  } catch (error) {
    return dateString;
  }
}

function date(dateString) {
  return format(formats.date, dateString);
}

function time(dateString) {
  return format(formats.time, dateString);
}

function dateTime(dateString) {
  return format(formats.dateTime, dateString);
}

function weekday(dateString) {
  return format(formats.weekday, dateString);
}

export default { date, time, dateTime, weekday };
