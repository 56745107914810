import React from "react";

import { Button, Fade } from "reactstrap";

import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer.js";
import Header from "components/headers/Header3";
import MobileHeader from "components/headers/Header";
import { FaChevronUp } from "react-icons/fa";

import FeatureServices from "components/features/FeatureServices.js";
// import FeatureEntities from "components/features/FeatureEntities.js";
import Blogs from "components/blogs/Blogs.js";
import ContactUs from "components/contact-us/ContactUs";
import useAPI from "hooks/useAPI";
import { showLoading, hideLoading } from "hooks/useLoading";

function Index() {
  showLoading();
  const { data } = useAPI("/api/home", {
    populate: ["about", "affiliates", "services"],
  });
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
    window.addEventListener('resize', handleWindowSizeChange);
     
    return function cleanup() {
      document.body.classList.remove("index-page");
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  });
  const isMobile = width <= 1080;

  if (!data) return null;

  const { about, services, affiliates } = data.data.attributes;
  hideLoading();
  return (
    <>
      <Fade appear={true}>
        <Navbar type="dark" />
        <div className="wrapper">
          {isMobile ? <div className="pt-20" ><br /><br /><br /><br /><br /></div> : <Header />}
          
          <FeatureServices about={about} services={services} />
          {/* <FeatureEntities affiliates={affiliates} /> */}
          <Blogs  />
          {/* <ContactUs /> */}
      <div className="pb-10"></div>
          <Footer />
          <Button
            className="btn-icon-only back-to-top show"
            color="default"
            name="button"
            type="button"
            onClick={() => {
              window.scrollTo(0, 0);
              document.body.scrollTop = 0;
            }}
          >
            <FaChevronUp />
            {/* <i className="ni ni-bold-up"></i> */}
          </Button>
        </div>
      </Fade>
    </>
  );
}

export default Index;
