import React from "react";

import ReactMarkdown from "react-markdown";
import { Container, Row, Col, Fade  } from "reactstrap";
import { useParams } from "react-router-dom";

import time from "lib/time";
import { baseUrl } from "lib/config";
import useAPI from "hooks/useAPI";
import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer.js";
import File from "components/Regulations/File";
import {showLoading, hideLoading} from "hooks/useLoading";

function BlogPost() {
  showLoading();
  React.useEffect(() => {
    document.body.classList.add("blog-post");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  });

  const { id } = useParams();

  const { data } = useAPI(id && `/api/decisions/${id}`, {
    populate: "*",
  });

  if (!data) return null;

  const { title, description, image, createdAt, file } = data.data.attributes;
  hideLoading();
  return (
    <Fade>
      <div className="wrapper">
        <Navbar type="transparent" />
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage: `url(${
                image.data
                  ? baseUrl + image.data.attributes.url
                  : require("assets/img/brand/sampleblog.png")
              })`,
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">{title}</h1>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <h6 className="category">{time.weekday(createdAt)}</h6>
                <h3 className="title">{title}</h3>
                <ReactMarkdown>{description}</ReactMarkdown>
              </Col>
            </Row>
          </Container>
        </section>

        {file.data && (
          <section className="section">
            <Container>
              <Row>
                <Col className="mx-auto" md="12">
                  <h3 className="title mb-3">ملفات ذات صلة</h3>
                  <File {...file.data.attributes} />
                </Col>
              </Row>
            </Container>
          </section>
        )}

        <Footer />
      </div>
    </Fade>
  );
}

export default BlogPost;
