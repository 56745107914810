import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col } from "reactstrap";

import time from "lib/time";
import { baseUrl } from "lib/config";

function RegulationCard({ title, url, createdAt, image }) {
  return (
    <Col lg="6">
      <Card className="card-blog card-background" data-animation="zooming">
        <div
          className="full-background"
          style={{
            backgroundImage: `url(${
              image.data
                ? baseUrl + image.data.attributes.url
                : require("assets/img/brand/news.png")
            })`,
          }}
        ></div>
        <Link to={url}>
          <CardBody>
            <div className="content-bottom">
              <h6 className="card-category text-white opacity-8">
                {time.date(createdAt)}
              </h6>
              <CardTitle tag="h5">{title}</CardTitle>
            </div>
          </CardBody>
        </Link>
      </Card>
    </Col>
  );
}

export default RegulationCard;
