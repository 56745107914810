import React from "react";

import { Row, Col , Fade } from "reactstrap";

import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer";
import FAQ from "components/accordions/faq.js";
import useAPI from "hooks/useAPI";
import {showLoading, hideLoading} from "hooks/useLoading";

function FAQView() {
  showLoading();
  const { data } = useAPI("/api/faq", {
    populate: "*",
  });

  if (!data) return null;

  const { faq } = data.data.attributes;
  hideLoading();
  return (
    <Fade>
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">أسئلة شائعة</h1>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <FAQ faqs={faq} />
        </section>
      </div>
      <Footer />
    </Fade>
  );
}

export default FAQView;
