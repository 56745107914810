import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components
import Navbar from "components/navbars/Navbar.js";

function Error() {
  React.useEffect(() => {
    document.body.classList.add("error-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-page");
    };
  });
  return (
    <>
      <Navbar type="dark" />
      <div className="wrapper">
        <div className="page-header error-page">
          <div
            className="page-header-image"
            
          ></div>
          <Container>
            <Row>
              <Col className="text-center" md="12">
                <h1 className="title">404</h1>
                <p className="lead bold">الصفحة غير موجودة :(</p>
                <h4 className="description text-default">
                عذرا لم نتمكن من إسترجاع المحتوي المطلوب
                </h4>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Error;
