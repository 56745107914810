import React from "react";

import { Card, CardBody, CardTitle, Col, CardFooter, Button } from "reactstrap";
import { FaLandmark, FaExternalLinkAlt } from "react-icons/fa";

function EserviceCard({ name, description, link }) {
  return (
    <Col lg="6" md="6">
      <Card className="card-project">
        <a href={link} target="_blank" rel="noreferrer">
          <div
            className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto"
            style={{ color: "#C59E5A " }}
          >
            <FaLandmark />
          </div>
        </a>
        <CardBody>
          <CardTitle className="mt-3 text-right" tag="h4">
            {name}
          </CardTitle>
          <p className="card-description text-right">{description}</p>
          <CardFooter>
            <a href={link} target="_blank" rel="noreferrer">
              <Button style={{ color: "#C59E5A " }} color="link" type="button">
                <FaExternalLinkAlt className="ml-1 " />
                موقع الجهه
              </Button>
            </a>
          </CardFooter>
        </CardBody>
      </Card>
    </Col>
  );
}

export default EserviceCard;
