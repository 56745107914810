import React from "react";

import { Container, Row, Col } from "reactstrap";

function Header() {
  return (
    <header className="header-4  skew-separator">
      <div className="header-wrapper">
        <div className="page-header header-video">
          <div className="overlay"></div>
          <video
            autoPlay="autoPlay"
            loop="loop"
            muted="muted"
            playsInline="playsInline"
          >
            <source
              src={require("assets/videos/Lights - 26607.mp4")}
              type="video/mp4"
            ></source>
          </video>
          <Container className="text-center">
            {/* <Row>
              <Col className="mx-auto text-center" lg="12">
                <div className="text-center d-none d-lg-block d-md-block">
                  <img
                    height={150}
                    alt=""
                    src={require("assets/img/brand/sawhite.png")}
                  ></img>
                </div>
                <h1 className="text-center video-text">
                  وزارة الشؤون الاجتماعية
                </h1>

                <h1 className="text-center display-3 text-white">
                  حكومة الوحدة الوطنية
                </h1>
              </Col>
            </Row> */}
          </Container>
        </div>
      </div>
    </header>
  );
}

export default Header;
