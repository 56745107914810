import React from "react";

import { Container, Row, Col, Fade } from "reactstrap";
import { showLoading, hideLoading } from "hooks/useLoading";

import useAPI from "hooks/useAPI";
import Footer from "components/footers/Footer";
import Navbar from "components/navbars/Navbar.js";
import EserviceCard from "components/Eservices/EserviceCard";

function Eservices() {
  showLoading();
  const { data } = useAPI("/api/home", {
    fields: "id",
    populate: "eservices.image",
  });

  if (!data) return null;

  const { eservices } = data.data.attributes;

  hideLoading();
  return (
    <Fade>
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">الخدمات الالكترونية</h1>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <section className="blogs-1">
            <Container>
              <Row className="align-items-center">
                {eservices.map((e) => (
                  <EserviceCard key={e.id} {...e} />
                ))}
              </Row>
            </Container>
          </section>
        </section>
      </div>
      <Footer />
    </Fade>
  );
}

export default Eservices;
