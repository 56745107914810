import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Row,
  Col,
  Alert,
} from "reactstrap";

function NavigationBar(props) {
  const [collapseOpen, toggleCollapse] = React.useState(false);
  const [dangerAlert, setDangerAlert] = React.useState(true);
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"));
    // initialise
    headroom.init();

    const pathname = window.location.pathname
    if (pathname === "/") {
      setDangerAlert(true);
    } else {
      setDangerAlert(false);
    }
    window.addEventListener('resize', handleWindowSizeChange);

    return function cleanup() {
      document.body.classList.remove("index-page");
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 1080;

  let navbarType = "";
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark";
      break;
    case "transparent":
      navbarType = "navbar-transparent";
      break;
    case "primary":
      navbarType = "bg-primary navbar-dark";
      break;
    case "white":
      navbarType = "bg-white";
      break;
    default:
      navbarType = "bg-default navbar-dark";
      break;
  }
  return (
    <>
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        id="dark-navbar-main"

      >
        <NavbarBrand w={250} className="mr-lg-5" to="/" tag={Link}>
          <img
            style={{ height: "75px" }}
            alt=""
            src={require("assets/img/brand/sawhitefull.png")}
          ></img>
        </NavbarBrand>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => toggleCollapse(!collapseOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Collapse
          id="navbar_global"
          navbar
          toggler="#navbar_global"
          isOpen={collapseOpen}
        >
          <div className="navbar-collapse-header" dir="rtl">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/index">
                  <img
                    style={{ height: "75px" }}
                    alt="..."
                    src={require("assets/img/brand/sagoldfull.png")}
                  ></img>
                </Link>
              </Col>
              <Col className="collapse-close text-left" xs="6">
                <button
                  className="navbar-toggler"
                  onClick={() => toggleCollapse(!collapseOpen)}
                >
                  <span></span>
                  <span></span>
                </button>
              </Col>
            </Row>
          </div>
          <Nav
            className="navbar-nav-hover align-items-lg-center mr-lg-auto"
            navbar
          >
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/">
                <span dir="rtl" className="nav-link-inner--text">
                  الرئيسية
                </span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/regulations">
                <span dir="rtl" className="nav-link-inner--text">
                  القرارات
                </span>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/news">
                <span dir="rtl" className="nav-link-inner--text">
                  الاخبار
                </span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
                <NavLink className="nav-link-icon" href="/announcements"  >
                  <span dir="rtl" className="nav-link-inner--text">الاعلانات</span>
                </NavLink>
              </NavItem> */}
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/eservices">
                <span dir="rtl" className="nav-link-inner--text">
                  الخدمات الالكترونية
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/offices">
                <span dir="rtl" className="nav-link-inner--text">
                  مكاتب الوزارة
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/entities">
                <span dir="rtl" className="nav-link-inner--text">
                  المراكز والجهات التابعة
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/gallery">
                <span dir="rtl" className="nav-link-inner--text">
                  معرض الصور
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/contact-us">
                <span dir="rtl" className="nav-link-inner--text">
                  تواصل معنا
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/complaints">
                <span dir="rtl" className="nav-link-inner--text">
                  الشكاوى
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="nav-link-icon" to="/faq">
                <span dir="rtl" className="nav-link-inner--text">
                  اسئلة شائعة
                </span>
              </NavLink>
            </NavItem>





            {/* <UncontrolledDropdown dir={"rtl"} nav>
              <DropdownToggle
                tag={NavLink}
                data-toggle="dropdown"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                role="button"
              >
                <i className="ni ni-tablet-button d-lg-none"></i>
                <span className="nav-link-inner--text"> المزيد..</span>
              </DropdownToggle>
              <DropdownMenu aria-labelledby="navbarDropdownMenuLink">
                <DropdownItem className="text-right" to="/gallery" tag={Link}>
                  معرض الصور
                </DropdownItem>
                <DropdownItem
                  className="text-right"
                  to="/contact-us"
                  tag={Link}
                >
                  تواصل معنا
                </DropdownItem>
                <DropdownItem
                  className="text-right"
                  to="/complaints"
                  tag={Link}
                >
                  الشكاوى
                </DropdownItem>
                <DropdownItem className="text-right" to="/faq" tag={Link}>
                  اسئلة شائعة
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Nav>


        </Collapse>
      </Navbar>
      {isMobile ? null : 
        <div dir="rtl" style={{ position: "absolute", top: "110px", width: "100%", direction: "rtl", zIndex: "999" }}>
          <Alert color="secondary" isOpen={dangerAlert}  >
            <span className="alert-icon">
              <i className="ni ni-like-2"></i>
            </span>
            <span className="alert-text" dir="rtl">
              <strong lang="ar" dir="rtl"> </strong>

            </span>


            <div className="row">
              <div className="col-md-2" >
                <h5>الخدمات الالكترونية
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => { setDangerAlert(false) }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>


                </h5></div>
              <div className="col-md-1"></div>
              <div className="col-md-2"><a href="https://mch.gate.mosa.ly" target="_blank" rel="noreferrer"><h6>الاستعلام عن منحة الاولاد </h6></a></div>
              <div className="col-md-2"><a href="https://mwd.gate.mosa.ly" target="_blank" rel="noreferrer"><h6>الاستعلام عن منحة الزوجة </h6></a></div>
              <div className="col-md-2"><a href="https://wcsg.gate.mosa.ly" target="_blank" rel="noreferrer"><h6>إدارة الحسابات المصرفية </h6></a></div>
              <div className="col-md-2"><a href="https://register.gate.mosa.ly" target="_blank" rel="noreferrer"><h6> التسجيل في منحة الزوجة </h6></a></div>
            </div>
          </Alert>
        </div>
      }
    </>
  );
}

NavigationBar.defaultProps = {
  type: "dark",
};

NavigationBar.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default NavigationBar;
