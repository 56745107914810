import useSWR from "swr";
import { stringify } from "qs";

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const options = {
  skipNulls: true,
  addQueryPrefix: true,
  sort: alphabeticalSort,
  encodeValuesOnly: process.env.NODE_ENV === "development",
};

function useAPI(url, query, arrayFormat) {
  let key = url;

  if (key && query) {
    key += stringify(query, { ...options, arrayFormat });
  }

  return useSWR(key);
}

export default useAPI;
