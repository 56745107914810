import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { SWRConfig } from "swr";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/argon-design-system.scss?v1.0.0";
import "unfetch/polyfill";

import Index from "views/Index.js";
import Eservices from "views/eservices.js";
import Entities from "views/entities";
import Regulations from "views/regulations.js";
import RegulationView from "views/RegulationView.js";
import BlogPost from "views/BlogPost.js";
import News from "views/News.js";
import Offices from "views/Offices.js";

// /Offices
import ContactMinister from "views/ContactMinister.js";
import AlbumGallery from "views/AlbumGallery";
import ContactUs from "views/ContactUs.js";
import Gallery from "views/Gallery.js";
import Error from "views/Error.js";
import FAQ from "views/faq.js";
import { fetcher } from "lib/http";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
<SWRConfig value={{ fetcher }}>
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route path="/faq" exact render={(props) => <FAQ {...props} />} />
      <Route
        path="/eservices"
        exact
        render={(props) => <Eservices {...props} />}
      />
      <Route
        path="/regulations"
        exact
        render={(props) => <Regulations {...props} />}
      />
      <Route
        path="/gallery/album/:id"
        exact
        render={(props) => <AlbumGallery {...props} />}
      />
      <Route
        path="/news/:id/:title"
        exact
        render={(props) => <BlogPost {...props} />}
      />
      <Route
        path="/regulations/:id"
        exact
        render={(props) => <RegulationView {...props} />}
      />
      <Route path="/news" exact render={(props) => <News {...props} />} />
      <Route
        path="/offices"
        exact
        render={(props) => <Offices {...props} />}
      />
      <Route
        path="/entities"
        exact
        render={(props) => <Entities {...props} />} />
      {/* offices  */}

      <Route
        path="/contact-us"
        exact
        render={(props) => <ContactUs {...props} />}
      />
      <Route
        path="/complaints"
        exact
        render={(props) => <ContactMinister {...props} />}
      />
      <Route
        path="/gallery"
        exact
        render={(props) => <Gallery {...props} />}
      />
      <Route path="/error" exact render={(props) => <Error {...props} />} />
      <Redirect to="/error" />
    </Switch>
  </BrowserRouter>
</SWRConfig>
);
