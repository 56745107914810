import React from "react";

import { Container, Row, Col, Fade } from "reactstrap";
import { showLoading, hideLoading } from "hooks/useLoading";

import useAPI from "hooks/useAPI";
import Footer from "components/footers/Footer";
import Navbar from "components/navbars/Navbar.js";
import EntitiesCard from "components/blogs/EntitiesCard";
import Pagination from "components/pagination/paginationComp";
function Entities() {
  showLoading();

  const { data } = useAPI("/api/home", {
    populate: ["affiliates"],
  });

  if (!data) return null;

  const { affiliates } = data.data.attributes;

  hideLoading();

  return (
    <Fade>
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">
                  المراكز والجهات التابعة
                </h1>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <section className="blogs-1">
            <Container>
              <Row>
                <Col className="mx-auto text-center my-5" lg="12">
                  <h3 className="display-3 text-center">
                    الجهات والمراكز التابعة
                  </h3>
                  <p className="lead text-center">
                    وزارة الشؤون الاجتماعية، تتكون من عدد من الادارات تغطي كافة
                    الجوانب الاجتماعية والتنموية التي تهم المجتمع، وتتكون كل
                    إدارة من عدد من الأقسام المتخصصة في مجالات عملها، بما يعكس
                    اهتمامات وأولويات المواطن.
                  </p>
                </Col>
              </Row>
              <Row className="align-items-center text-right">
                {affiliates.map((e) => (
                  <EntitiesCard key={e.id} {...e} />
                ))}
              </Row>
            </Container>
          </section>
        </section>
        <Pagination pagination={data.meta.pagination} />
      </div>
      <Footer />
    </Fade>
  );
}

export default Entities;
