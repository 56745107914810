import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import ImageViewer from "react-simple-image-viewer";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer.js";
import { baseUrl } from "lib/config";
import useAPI from "hooks/useAPI";
import {showLoading, hideLoading} from "hooks/useLoading";

function ContactUs() {
  showLoading();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const { id } = useParams();

  const { data } = useAPI(id && "/api/albums/" + id, {
    populate: "*",
  });

  if (!data) return null;

  const { title, description, images } = data.data.attributes;
  hideLoading();
  return (
    <>
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">{title}</h1>
                <p>{description}</p>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="align-items-center">
              {images.data.map(({ id, attributes: { formats } }, index) => (
                <Col key={id} lg={"4"}>
                  <Card
                    onClick={() => openImageViewer(index)}
                    className="card-blog card-background"
                    data-animation="zooming"
                  >
                    <div
                      className="full-background"
                      style={{
                        backgroundImage: `url(${baseUrl + formats.small.url})`,
                      }}
                    ></div>
                    <CardBody>
                      <div className="content-bottom"></div>
                    </CardBody>
                  </Card>
                </Col>
              ))}

              {isViewerOpen && (
                <div style={{ zIndex: "999" }}>
                  <ImageViewer
                    src={images?.data.map(
                      (img) => baseUrl + img.attributes.url
                    )}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    leftArrowComponent={<FaChevronLeft />}
                    rightArrowComponent={<FaChevronRight />}
                  />
                </div>
              )}
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
