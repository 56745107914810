const showLoading = () => {
    const spinner = document.getElementById('spinner');
    if (spinner && spinner.hasAttribute('hidden')) {
        spinner.removeAttribute('hidden', 'true');
    }

}
const hideLoading = () => {
    const spinner = document.getElementById('spinner');
    if (spinner && !spinner.hasAttribute('hidden')) {
        setTimeout(()=> {
            spinner.setAttribute('hidden', 'true');
        }, 500)
    }

}
module.exports = { showLoading, hideLoading };