import React, { Fragment } from "react";

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

function Paging({ pagination }) {
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    if (!pagination) return;

    let tmpPages = [];
    for (let index = 0; index < pagination.pageCount; index++) {
      const element = (
        <PaginationItem
          className={pagination.page === index + 1 ? "active" : ""}
        >
          <PaginationLink href={`?page=${index + 1}`}>
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      );
      tmpPages.push(element);
    }
    setPages(tmpPages);
  }, []);

  return (
    <>
      <footer className="mt-1 pb-5 container">
        <Pagination
          className="pagination float-left"
          listClassName=" float-left"
        >
          {pages.map((p, i) => (
            <Fragment key={i}>{p}</Fragment>
          ))}
        </Pagination>
      </footer>
    </>
  );
}

export default Paging;
