import React from "react";

import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import time from "lib/time";
import useAPI from "hooks/useAPI";
import { baseUrl } from "lib/config";

function Blogs() {
  const { data } = useAPI("/api/news-stories", {
    fields: ["title", "date"],
    populate: "image",
    sort: "date:desc",
    pagination: {
      pageSize: 4,
    },
  });

  
  if (!data) return null; 
  return (
    <section className="blogs-2">
      <Container fluid>
        <Row className="mb-md-5">
          <Col className="mx-auto" md="8">
            <h3 className="display-3 text-center">أخر الاخبار</h3>
            <p className="lead text-center">
              وزارة الشؤون الاجتماعية، التي تتكون من عدد من الادارات تغطي كافة
              الجوانب الاجتماعية والتنموية التي تهم المجتمع، وتتكون كل إدارة من
              عدد من الأقسام المتخصصة في مجالات عملها، بما يعكس اهتمامات
              وأولويات المواطن.
            </p>
          </Col>
        </Row>
        <Row>
          {data?.data?.map(({ id, attributes: { title, image, date } }) => (
            <Col key={id} lg="3">
              <Card
                className="card-blog card-background"
                data-animation="zooming"
              >
                <div
                  className="full-background"
                  style={{
                    backgroundImage: `url(${
                      image.data 
                        ? baseUrl + image.data.attributes.url
                        : require("assets/img/brand/news.png")
                    })`,
                  }}
                />
                <Link to={`/news/${id}/${title.replace(/\s+/g, '-')}`}>
                  <CardBody>
                    <div className="content-bottom">
                      <p className="text-secondary">{time.weekday(date)}</p>
                      <CardTitle tag="h5">{title}</CardTitle>
                    </div>
                  </CardBody>
                </Link>
              </Card>
            </Col>
          ))}

          <Col className="text-center" lg="12">
            <Link to="/news">
              <h5 className="text-center text-default opacity-8">المزيد...</h5>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Blogs;
