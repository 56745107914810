import FormInput from "components/form/FormInput";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  Card,
  Badge,
  CardBody,
  Form,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
} from "reactstrap";

import { contactSchema } from "lib/schemas";
import {
  FaAt,
  FaEnvelope,
  FaEnvelopeOpenText,
  FaMobile,
  FaUser,
} from "react-icons/fa";
import messageService from "services/messageService";

function Complaint() {
  const methods = useForm({
    resolver: yupResolver(contactSchema),
    mode: "onTouched",
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) =>
    messageService.create(data).finally(() => methods.reset());

  return (
    <div className="main mb-5">
      <Container fluid>
        <Row className="mt-0 mb-4 pt-0">
          <Col className="ml-auto mr-auto text-center mt-5" md="8">
            <Badge color="default">اترك شكوى</Badge>
            <h1 className="title text-center">تواصل مع الوزارة</h1>
            <h4 className="desc text-center">
              سواء كانت لديك أسئلة أو كنت ترغب تقديم شكوى
            </h4>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto" md="6">
            <Card className="bg-secondary p-3" id="contact-form">
              <CardBody>
                <FormProvider {...methods}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md="6">
                        <FormInput
                          name="purpose"
                          type="hidden"
                          defaultValue="complaint"
                        />
                        <FormInput
                          aria-label="الاسم"
                          placeholder="الاسم"
                          name="name"
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaUser />
                            </InputGroupText>
                          </InputGroupAddon>
                        </FormInput>
                      </Col>
                      <Col md="6">
                        <FormInput
                          aria-label="رقم الهاتف"
                          placeholder="رقم الهاتف"
                          type="tel"
                          name="phone"
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaMobile />
                            </InputGroupText>
                          </InputGroupAddon>
                        </FormInput>
                      </Col>
                    </Row>
                    <FormInput
                      placeholder="البريد الالكتروني"
                      type="email"
                      name="email"
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaAt />
                        </InputGroupText>
                      </InputGroupAddon>
                    </FormInput>
                    <FormInput placeholder="العنوان" name="subject">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaEnvelope />
                        </InputGroupText>
                      </InputGroupAddon>
                    </FormInput>
                    <FormInput
                      className="form-control-alternative"
                      placeholder="الرسالة"
                      rows="6"
                      type="textarea"
                      name="message"
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaEnvelopeOpenText />
                        </InputGroupText>
                      </InputGroupAddon>
                    </FormInput>
                    <Row className="justify-content-end">
                      <Col className="text-center" md="12">
                        <Button color="default" type="submit">
                          أرسل
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Complaint;
