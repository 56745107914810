import React from "react";
import {showLoading, hideLoading} from "hooks/useLoading";
import { Fade } from "reactstrap";
import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer.js";
import ContactUsForm from "components/contact-us/ContactUs.js"; 
 
function ContactUs() {
  return (
    <Fade> 
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
        </div>
        <ContactUsForm />
        <h1 className="title text-center mb-5">موقع الوزارة</h1>

        <iframe
          title="موقع الوزارة"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3350.6401963103617!2d13.183043915482555!3d32.881237985970444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a893bfb17cdef7%3A0x683199f6b0733ddc!2z2YjYstin2LHYqSDYp9mE2LTYpNmI2YYg2KfZhNin2KzYqtmF2KfYudmK2Kk!5e0!3m2!1sen!2sly!4v1668531024379!5m2!1sen!2sly"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />

        <Footer />
      </div>
    </Fade>
  );
}

export default ContactUs;
