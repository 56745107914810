import React, { useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

function FAQ({ faqs }) {
  return (
    <div className="accordion-1 text-cente">
      <Container>
        <Row>
          <Col className="mx-auto text-center" md="12">
            <h2 className="title mb-3 mt-5 text-cente">أسئلة شائعة</h2>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto" md="12">
            {!faqs.length && <p>تعدر العثور علي بيانات</p>}

            <div className="accordion text-dark" id="accordion">
              {faqs.map(({ id, question, answer }) => (
                <Question key={id} question={question} answer={answer} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function Question({ question, answer }) {
  const [open, setOpen] = useState(false);

  return (
    <Card style={{ borderBottom: "1px solid #cdcdcd" }}>
      <CardHeader>
        <h5 className="mb-0">
          <Button
            aria-expanded={open}
            onClick={() => setOpen(!open)}
            className="w-100 text-dark text-right"
            color="link"
            type="button"
          >
            {question}
            {/* <i className="ni ni-bold-down float-left pt-1"></i> */}
            <div className="float-left pt-1">
              {open ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </Button>
        </h5>
      </CardHeader>
      <Collapse isOpen={open}>
        <CardBody className="opacity-8">{answer}</CardBody>
      </Collapse>
    </Card>
  );
}

export default FAQ;
