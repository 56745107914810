import React from "react";

import { Card, CardBody, CardTitle, Container, Row, Col, Fade } from "reactstrap";

import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer.js";
import { Link } from "react-router-dom";
import useAPI from "hooks/useAPI";
import { baseUrl } from "lib/config";
import { showLoading, hideLoading } from "hooks/useLoading";

function Gallery() {
  showLoading();

  const { data } = useAPI("/api/albums", {
    populate: "*",
    sort: "id:desc",
    pagination: {
      pageSize: 8,
      // page: new URLSearchParams(window.location.search).get("page") || 1,
    },
  });
  console.log(data)
  if (!data) return null;
  hideLoading();
  return (
    <Fade>
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">معرض الصور</h1>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="align-items-center">
              {data.data.map(({ id, attributes: { title, images } }) => (
                <Col key={id} lg={"6"}>
                  <Link to={`/gallery/album/${id}`}>
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage: `url(${baseUrl + 
                            images.data[0].attributes.url
                            })`,
                        }}
                      ></div>
                      <CardBody>
                        <div className="content-bottom">
                          <CardTitle tag="h5">{title}</CardTitle>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Fade>
  );
}

export default Gallery;
