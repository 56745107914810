import React from "react";

import { Container, Row, Col } from "reactstrap";
import { FaHandshake, FaFemale, FaUsers, FaChild } from "react-icons/fa";

function FeatureServices({ about, services }) { 
  return (
    <div className="section features features-5">
      <Container>
        <Row>
          <Col className="text-center mx-auto" md="12">
            <h3 className="display-3 text-center">نبذة عن الوزارة</h3>
            <p className="lead text-justify" style={{ whiteSpace: "pre-line" }}>
              {about}
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="align-items-center">
          <Col className="mt-md-10" lg="12">
            <h3 className="display-3 text-center my-5">خدمات الوزارة</h3>
            <Row>
              {services.map((s) => (
                <Col key={s.id} md="12">
                  <div className="icon icon-shape icon-shape-default shadow rounded-circle mb-4">
                    {s.title === "الأسرة" ? <FaUsers /> : (s.title === "الطفل" ? <FaChild /> : <FaFemale />)} 
                  </div>

                  <h5 className="display-5">{s.title}</h5>
                  <p
                    className="text-justify"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {s.description}
                  </p>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FeatureServices;
