import http from "lib/http";

function create(data) {
  return http.request({
    method: "POST",
    endpoint: "/api/messages",
    data: { data },
  });
}

const messageService = { create };

export default messageService;
