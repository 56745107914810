import React from "react";

import {
  Button,
  NavLink,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { FaFacebookSquare } from "react-icons/fa";

function Footer() {
  return (
    <>
      <footer className="footer footer-big bg-secondary mt-5">
        <Container>
          <div className="content">
            <Row>
              <Col md="3">
                <div className="column text-center">
                  <img
                    alt="..."
                    className="logo"
                    height={100}
                    src={require("assets/img/brand/sagold.png")}
                  ></img>
                </div>
              </Col>
              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3 text-gray">الوزارة</h4>
                  <ul>
                    <li>
                      <a href="/" >
                        نبذة
                      </a>
                    </li>
                    <li>
                      <a href="/eservices" >
                        الخدمات الالكترونية
                      </a>
                    </li>
                    <li>
                      <a href="/entities" >
                        الجهات التابعة
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </Col>

              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3 text-gray">الموارد</h4>
                  <ul>
                    <li>
                      <a href="/news" >
                        الاخبار
                      </a>
                    </li> 
                    <li>
                      <a href="/gallery" >
                        معرض الصور
                      </a>
                    </li> 
                    <li>
                      <a href="/faq" >
                        الاسئلة الشائعة
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3 text-gray">إتصل بنا</h4>
                  <ul>
                    <li>
                      <a href="/complaints" >
                        الشكاوى 
                      </a>
                    </li>
                    <li>
                      <a href="/contact-us" >
                        تواصل معنا
                      </a>
                    </li>
                  </ul>
                  <div className="btn-wrapper profile text-left mt-3">
                    <Button
                      color=""
                      href="https://www.facebook.com/Ministry.of.social.affairs"
                      id="tooltip860625601"
                      size="sm"
                      target="_blank"
                    >
                      <FaFacebookSquare />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip860625601">
                      Like us
                    </UncontrolledTooltip>
                  </div>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md="9">
                {/* <div className="column">
                  <nav>
                    <ul>
                      <li className="d-inline-block">
                        <NavLink href="#" target="_blank">
                          الشروط والاحكام
                        </NavLink>
                      </li>
                      <li className="d-inline-block">
                        <NavLink href="#" target="_blank">
                          سياسة الخصوصية
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="footer-description">
                  وزارة الشؤون الاجتماعية، التي تتكون من عدد من الادارات تغطي
                  كافة الجوانب الاجتماعية والتنموية التي تهم المجتمع، وتتكون كل
                  إدارة من عدد من الأقسام المتخصصة في مجالات عملها، بما يعكس
                  اهتمامات وأولويات المواطن.
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
