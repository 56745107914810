import * as yup from "yup";

import "yup-phone-lite";

function takoon(label, msg) {
  return label.includes("ة") ? msg.replace("يكون", "تكون") : msg;
}

function harf(num, msg) {
  return num > 10 ? msg.replace("حروف", "حرف") : msg;
}

yup.setLocale({
  mixed: {
    required: "يجب تعبئة ${label}",
    notOneOf: "يجب اختيار ${label}",
    notType: ({ label, type }) => {
      const types = {
        number: "عدد",
        string: "نص",
      };
      const msg = `${label} يجب أن يكون ${types[type]}`;

      return takoon(label, msg);
    },
  },
  array: {
    max: ({ label, max }) => {
      let msg = `${label} يجب أن يكون على الأكثر ${max}`;

      msg = takoon(label, msg);
      msg = harf(max, msg);

      return msg;
    },
  },
  string: {
    email: "${label} غير صالح",
    min: ({ label, min }) => {
      let msg = `${label} يجب أن يكون على الأقل ${min} حروف`;

      msg = takoon(label, msg);
      msg = harf(min, msg);

      return msg;
    },
    max: ({ label, max }) => {
      let msg = `${label} يجب أن يكون على الأكثر ${max} حروف`;

      msg = takoon(label, msg);
      msg = harf(max, msg);

      return msg;
    },
  },
  number: {
    positive: "${label} يجب أن يكون عدد موجب",
    integer: "${label} يجب أن يكون عدد صحيح",
    min: "${label} يجب أن يكون أكبر من أو يساوي ${min}",
    max: "${label} يجب أن يكون أقل من أو يساوي ${max}",
  },
});

export default yup;
