import React from "react";

import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink, Fade 
} from "reactstrap";
import {showLoading, hideLoading} from "hooks/useLoading";

import Navbar from "components/navbars/Navbar.js";
import Footer from "components/footers/Footer";
import RegulationCard from "components/Regulations/RegulationCard";
import useAPI from "hooks/useAPI";

function Regulations() {
  showLoading();
  const { data } = useAPI("/api/decisions", {
    populate: "*",
    sort: "id:desc",
    pagination: {
      pageSize: 4,
    },
  });

  if (!data) return null;
  hideLoading();
  return (
    <Fade>
      <Navbar type="transparent" />
      <div className="wrapper">
        <div className="page-header page-header-small header-filter">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/brand/sampleblog.png") + ")",
            }}
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">قرارات الوزارة</h1>
              </Col>
            </Row>
          </div>
        </div>
        <section className="section">
          <section className="blogs-1">
            <Container>
              <Row className="mb-5">
                <Col md="8">
                  <h3 className="display-3">أحدث القرارات</h3>
                </Col>
              </Row>
              <Row className="align-items-center">
                {data.data.map(({ id, attributes }) => (
                  <RegulationCard
                    key={id}
                    url={`/regulations/${id}`}
                    {...attributes}
                  />
                ))}
              </Row>
            </Container>
          </section>
        </section>
        <footer className="mt-1 pb-5 container">
          <Pagination
            className="pagination float-left"
            listClassName=" float-left"
          >
            <PaginationItem>
              <PaginationLink aria-label="Previous" href="#pablo">
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="ni ni-bold-right"></i>
                </span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem className="active">
              <PaginationLink href="#pablo">1</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink href="#pablo">2</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink href="#pablo">...</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink href="#pablo">6</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink aria-label="Next" href="#pablo">
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="ni ni-bold-left"></i>
                </span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>

          <div className="text-right">
            <p>عرض 6 من أصل 36</p>
            <div></div>
          </div>
        </footer>
      </div>
      <Footer />
    </Fade>
  );
}

export default Regulations;
