import React from "react";

import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function BlogCard({ name, localityNumber, link }) {
  return (
    <section className="blogs-3">
      <Container>
        <Row>
          <Col className="mx-auto" lg="12" md="8">
            <Card className="card-blog card-plain blog-horizontal mb-5">
              <Row>
                <Col lg="4">
                  <div className="card-image shadow">
                    <Link to={{ pathname: link }} target="_blank">
                      <img
                        alt={name}
                        className="img rounded"
                        src={require("assets/img/brand/news.png")}
                        // src={
                        //   image?.data
                        //     ? baseUrl + image.data.attributes.url
                        //     : require("assets/img/brand/news.png")
                        // }
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg="8">
                  <CardBody>
                    <CardTitle tag="h3">
                      <Link
                        to={{ pathname: link }}
                        target="_blank"
                        className="text-dark"
                      >
                        {name}
                      </Link>
                    </CardTitle>
                    <p className="card-description mt-2">{localityNumber}</p>
                    <Link
                      to={{ pathname: link }}
                      target="_blank"
                      className="text-gray mt-2"
                    >
                      تواصل مع المكتب
                    </Link>
                    <br />
                    <Link
                      to={{ pathname: link }}
                      target="_blank"
                      className="text-gray mt-2"
                    >
                      موقع المكتب
                    </Link>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default BlogCard;
