import { baseUrl } from "lib/config";
import React from "react";

import { Card, CardBody, CardTitle, Col } from "reactstrap";

function EserviceCard({ name, description, url, image }) {
  return (
    <Col lg="6">
      <Card className="card-blog card-background" data-animation="zooming">
        <div
          className="full-background"
          style={{
            backgroundImage: `url(${
              image.data
                ? baseUrl + image.data.attributes.url
                : require("assets/img/brand/news.png")
            })`,
          }}
        ></div>
        <a target="_blank" rel="noreferrer" href={url}>
          <CardBody>
            <div className="content-bottom">
              <h6 className="card-category text-white opacity-6">
                {description}
              </h6>
              <CardTitle tag="h5">{name}</CardTitle>
            </div>
          </CardBody>
        </a>
      </Card>
    </Col>
  );
}

export default EserviceCard;
