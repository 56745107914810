import { useId } from "react";
import { FormFeedback, FormGroup, Input, InputGroup, Label } from "reactstrap";

import { useController } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

function FormInput({ name, defaultValue = "", children, type, ...props }) {
  const { field, fieldState, formState } = useController({
    name,
    defaultValue,
  });

  const id = useId();

  const isValid = !fieldState.error;

  return (
    <FormGroup className={type === "hidden" ? "d-none" : ""}>
      <Label for={id}>{props.placeholder}</Label>
      <InputGroup className="input-group-alternative">
        {children}
        <Input
          id={id}
          type={type}
          {...props}
          {...field}
          ref={undefined}
          innerRef={field.ref}
          invalid={!isValid}
        />
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => (
            <FormFeedback className="p-2">{message}</FormFeedback>
          )}
        />
      </InputGroup>
    </FormGroup>
  );
}

export default FormInput;
