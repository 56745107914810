import yup from "./yup";

export const contactSchema = yup.object({
  name: yup.string().trim().required().label("الاسم"),
  phone: yup
    .string()
    .trim()
    .phone("LY", "يجب ان يكون رقم هاتف ليبي أو دولي مع مفتاح البلد")
    .required()
    .label("رقم الهاتف"),
  email: yup.string().email().trim().required().label("البريد الالكتروني"),
  subject: yup.string().trim().required().label("العنوان"),
  message: yup.string().trim().required().label("الرسالة"),
  purpose: yup.string().oneOf(["contact", "complaint"]),
});
